@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.Frame {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: toRem(40);
    min-height: 100vh;

    border: toRem(16) solid $primary;
    background: $light;

    overflow: hidden;

    @media (min-width: 992px) {
        padding-top: toRem(80);
        
        border: toRem(20) solid $primary;
        background-image: url("../../../assets/img/logo-rotate.png");
        background-repeat: no-repeat;
        background-position: 117% 89%;
        background-size: 25%;
    }

    &--intro {
        justify-content: center;
        padding-top: 0;
        background-image: none;
        height: 100vh;
    }

    &:after, &:before {
        content: "";
        position: absolute;
        height: toRem(155);
        width: toRem(155);
        background: $primary;
        transform: rotate(135deg);
        top: toRem(-77);
        right: toRem(-77);
    }

    &:after {
        top: unset;
        left: toRem(-77);
        bottom: toRem(-77);
        right: unset;
    }
}