@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.cloud-item {
    color: $primary;

    &:nth-child(even) {
        color: $secondary;
    }

    &__link {
        color: inherit;
        font-family: $font-family-primary;
        display: block;
        padding: 0.125rem 0.25rem;
        position: relative;
        text-decoration: none;

        &--reallyHigh {
            font-size: toRem(40);
        }

        &--high {
            font-size: toRem(34);
        }

        &--medium {
            font-size: toRem(30);
        }

        &--low {
            font-size: toRem(24);
        }

        &--reallyLow {
            font-size: toRem(16);
        }
    }
}