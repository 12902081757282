@import '../../../assets/stylesheets/settings/toRem';

.portfolio-items {
    margin-bottom: toRem(42);

    &__container {
        margin-top: toRem(24);
        margin-bottom: toRem(32);
        padding: 0;
        list-style-type: none;

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: toRem(32);
        }
    
        @media (min-width: 1100px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}