@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.list-item {
    display: grid;
    align-items: center;
    grid-template-columns: toRem(16) toRem(48) 1fr;
    grid-gap: toRem(8);
    list-style-type: none;

    margin-bottom: toRem(24);

    @media (min-width: 768px) {
        grid-gap: toRem(12);
    }

    .fas {
        color: $primary;
        font-size: toRem(14);
    }

    &__year {
        padding: 0 toRem(6);
        background-color: $secondary;
        color: $white;
        font-family: $font-family-secondary;
    }

    &__icon-container {
        display: grid;
        grid-template-columns: toRem(12) toRem(24);
        grid-gap: toRem(4);
    }
}