@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";
@import "../../../assets/stylesheets/settings/mixins";

.link {
    @include button;

    &.ghost {
        background: $white;
    
        color: $primary;
        border: 2px solid $primary;

        &:hover {
            color: $white;
            border: 2px solid $primary;
        }
    }

    &.small {
        padding: toRem(4) toRem(8);
    }

    &.secondary {
        color: $white;
        background-color: $primary;

        &:hover {
            color: $primary;
            background-color: $white;
        }
    }

    &:hover {
        background-color: $primary;
        border: 2px solid $primary;
    }
}