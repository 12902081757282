@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.strength-item {
    padding: toRem(24) toRem(16);

    color: $white;
    text-align: center;
    background-color: $secondary;
    border-radius: toRem(10);

    &:hover {
        background-color: $primary; 
    }

    @media (min-width: 768px) {
        padding: toRem(32) toRem(16);
        width: calc(50% - 16px);
    }

    @media (min-width: 1100px) {
        width: calc(33% - 24px);
    }

    &__title {
        margin: 0;

        font-family: $font-family-primary;
        font-size: toRem(40);
    }

    &__subtitle {
        margin-bottom: 0;
        font-family: $font-family-secondary;
    }
}