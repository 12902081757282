@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.footer {
    padding: toRem(16);
    background-color: $primary;

    @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content:space-between;
    }

    &__img-container {
        margin: 0;
        width: toRem(40);
    }

    &__img {
        max-height: 100%;
        max-width: 100%;
    }

    &__title {
        margin: toRem(8) 0 0 toRem(8);
        color: $white;
        font-family: $font-family-primary;

        @media (min-width: 768px) {
            margin: 0 0 0 toRem(10);
        }
    }

    &__logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 768px) {
            flex-direction: row;
        }
    }

    &__list {
        display: flex;
        justify-content: center;

        margin: toRem(48) auto toRem(8) auto;
        padding: 0;
        list-style-type: none;

        @media (min-width: 768px) {
            margin: 0;
            align-items: flex-end;
        }
    }
}