@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.navbar {
    display: flex;
    justify-content: flex-end;

    width: 100%;
    z-index: 1;

    @media (max-width: 767px) {
        position: absolute;
        top: 0;
        right: 0;
    }

    @media (min-width: 768px) {
        margin-top: toRem(48);

        &--is-sticky {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;

            margin-top: 0;
        }
    }

    &__hamburguer {
        width: toRem(64);
        height: toRem(64);
        position: fixed;
        border: none;
        border-radius: 50%;
        appearance: none;
        z-index: 1;
        padding: toRem(8);
        background-color: $primary;
    
        @media (min-width: 768px) {
          display: none;
        }
    }

    &__hamburguer-icon {
        position: absolute;
        top: toRem(16);
        right: toRem(12);

        width: toRem(40);
        height: toRem(6);

        transition: 0.3s;
        background-color: $white;
    
        &--is-open {
            top: toRem(27);
            transform: rotate(48deg);
        
            &:nth-last-child(2) {
                transform: rotate(133deg);
            }
        
            &:last-of-type {
                opacity: 0;
            }
        }

        &:nth-last-child(2) {
            top: toRem(29);
        }
      
        &:last-of-type {
            top: toRem(42);
        }
    }

    &__container {
        display: none;        
        padding: toRem(80) 0 0 0;
        margin: 0;
        
        width: 100%;
        
        background-color: $primary;
        transition: opacity 0.5s, left 1s;

        &--is-open {
            position: fixed;
            top: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media (min-width: 768px) {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;

            padding: 0 toRem(16);
        }

        @media (max-width: 767px) {
            height: 100vh;
        }
    }

    &__list {
        margin: 0 2px 0 0;
        padding: 0;
        list-style-type: none;

        @media (min-width: 768px) {
            display: flex;
        }
    }

    &__link {
        display: block;
        padding: toRem(16);

        color: $white;
        font-size: toRem(18);
        font-family: $font-family-secondary;
        text-decoration: none;
        text-align: center;

        &:hover {
            background-color: $secondary;
        }

        &--is-active {
            background-color: $secondary;
        }
    }

    &__title {
        display: flex;
        align-items: center;

        margin: 0;
        padding-left: toRem(10);

        background-color: $primary;
        color: $white;
        font-family: $font-family-primary;
        font-size: toRem(16);
    }
}