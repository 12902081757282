@import './generic/box-sizing';
@import './generic/normalize';

@import './settings/toRem';
@import './settings/variables';

* {
    &:focus{
        outline: 4px solid black;
        outline-offset: 2px;
    }
}