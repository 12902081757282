@import '../settings/variables';
@import '../settings/toRem';

@mixin button {
    padding: toRem(8) toRem(24);

    background: $secondary;

    color: $white;
    text-align: center;
    text-decoration: none;
    font-family: $font-family-secondary;
    border: 2px solid $secondary;
    border-radius: toRem(2);
}

@mixin ghostButton {
    padding: toRem(8) toRem(24);

    background-color: $white;

    color: $secondary;
    text-align: center;
    text-decoration: none;
    font-family: $font-family-secondary;
    border: 2px solid $secondary;
    border-radius: toRem(2);
}

@mixin input-styles {
    width: 100%;

    margin-bottom: toRem(24);
    padding: toRem(8) toRem(8) toRem(8) toRem(8);

    color: $primary;
    background-color: transparent;
    font-family: $font-family-secondary;

    border: 2px solid $primary;
    border-radius: toRem(2);
}