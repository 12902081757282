@import "../../assets/stylesheets/settings/toRem";

.portfolio {
    &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        @media (min-width: 768px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: toRem(32);
        }
    
        @media (min-width: 1100px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}