@import "../../assets/stylesheets/settings/toRem";

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
      
    to {
        transform: rotate(360deg);
    }
}

.articles {
    &__img {
        width: toRem(30);
        animation: rotate 2s linear infinite;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}