@import '../../../assets/stylesheets/settings/toRem';
@import '../../../assets/stylesheets/settings/variables';

.portfolio-item { 
    @media (max-width: 767px) {
        margin-bottom: toRem(24);
    }   

    &--active {
        .portfolio-item__img-container {
            background-color: $primary;
        }

        .portfolio-item__img-text {
            position: absolute;
        }

        .portfolio-item__img {
            opacity: 0.1;
        }
    }

    &__img-container {
        width: 100%;
        height: toRem(150);
        position: relative;
        display: block;
        transition: all 0.5s;
        overflow: hidden;

        @media (min-width: 575px) {
            height: toRem(300);
        }

        @media (min-width: 768px) {
            height: toRem(175);
        }
    }

    &__img {
        width: 100%;
    }

    &__img-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        top: 0;
        padding: toRem(20) toRem(40) toRem(40) toRem(40);

        color: $white;
        font-family: $font-family-secondary;
        font-weight: 400;
        text-align: center;
        transition: all 0.5s;

        span {
            font-weight: bold;
        }
    }

    &__button-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: toRem(8);
    }
}