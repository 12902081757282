@import '../../../assets/stylesheets/settings/variables';
@import '../../../assets/stylesheets/settings/toRem';
@import '../../../assets/stylesheets/settings/mixins';

.language {
    position: relative;
    display: flex;
    margin: toRem(12) 0;

    background-color: $white;
    border: 4px solid $white;

    @media (min-width: 768px) {
        margin: 0;
        border: none;
    }

    &__label {
        font-family: $font-family-primary;
        padding: 8px;
        border: none;
        color: $white;
        background-color: $primary;

        &--active, &:hover {
            background-color: $white;
            color: $primary;
        }
    }

    &__container {
        display: flex;
    }

    &__fieldset {
        display: flex;
        padding: 0;
        border: 1px solid transparent;
    }

    &__title {
        position: absolute;
        left: -9999em;
        height: 0%;
        border: none;
    }

    &__input {
        position: absolute;
        left: -9999px;

        &:focus+.language__label {
            outline: 2px solid black;
        }
    }
}