@import './assets/stylesheets/styles.scss';

#root {
    display: flex;
    flex-direction: column;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
