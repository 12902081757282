@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";
@import "../../../assets/stylesheets/settings/mixins";

.button {
    @include button;

    &:hover, &:disabled {
        background-color: $primary;
        border: 2px solid $primary;
    }

    &--secondary {
        background: $primary;
        border: 2px solid $primary;

        &:hover, &:disabled {
            background: $secondary;
            border: 2px solid $secondary;
        }
    }

    &--ghost {
        background: $white;
    
        color: $primary;
        border: 2px solid $primary;

        &:hover {
            color: $white;
            border: 2px solid $primary;
        }
    }

    &--ghost-secondary {
        background: $white;
    
        color: $secondary;
        border: 2px solid $secondary;

        &:hover {
            background-color: $secondary;
            color: $white;
            border: 2px solid $secondary;
        }
    }

    &:disabled {
        cursor:not-allowed;
    }
}