$primary: #6C0345;
$secondary: #B85814;
//#4E3A3A
$ternary: #E9C874;
$light: #FFFEFA;
$white: #fff;
$black: #000;
$gray: #828282;
$light-gray: #dedede;

$font-family-primary: "Lilita One", sans-serif;
$font-family-secondary: "Roboto", sans-serif;

$font-size-base: 16px;
$font-size-m: 18px;
$font-size-xl: 42px;
$font-size-xxl: 56px;

$z-index-infra: -1;
$z-index-normal: 0;
$z-index-md: 1;
$z-index-lg: 2;