@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    padding: toRem(16) toRem(16) toRem(120) toRem(16);

    @media (min-width: 768px) {
        max-width: toRem(600);
        padding: toRem(32) toRem(16) toRem(48) toRem(16);  
    }

    @media (min-width: 1100px) {
        max-width: toRem(900); 
    }
}