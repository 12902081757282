@import "../../../assets/stylesheets/settings/toRem";
@import "../../../assets/stylesheets/settings/variables";
@import "../../../assets/stylesheets/settings/mixins";

.form-element {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 1px;
    margin-bottom: 0;

    &__text {
        order: 1;
        margin-top: 0;
        margin-bottom: toRem(8);
        font-family: $font-family-secondary;
        font-weight: 400;
    }

    input, textarea {
        order: 2;
    }

    &__asterisk {
        margin-left: toRem(4);
    }

    input[type="radio"] {
        position: absolute;
        z-index: -1;

        +label {
            @include ghostButton;
        }

        &:checked+label {
            @include button;
        }

        &:focus+label {
            outline: 4px solid black;
        }
    }
}